import React from "react";
import IleriNavbar from "../../components/ileri-page-components/IleriNavbar";
import IleriHeroSection from "../../components/ileri-page-components/IleriHeroSection";
import IleriYoutubeSection from "../../components/ileri-page-components/IleriYoutubeSection";
import IleriAmenitiesSection from "../../components/ileri-page-components/IleriAmenitiesSection";
import LandingPageFooter from "../../components/landing-page-components/LandingPageFooter";

const ComonLandingPage = ({ data }) => {
  return (
    <main>
      <IleriNavbar data={data} />
      <IleriHeroSection data={data} />
      <IleriYoutubeSection data={data} />
      <IleriAmenitiesSection data={data} />
      <LandingPageFooter />
    </main>
  );
};

export default ComonLandingPage;
