import { MdDashboard, MdSettings, MdLogout } from "react-icons/md";
import { HiOutlineSupport } from "react-icons/hi";
import { RiGitRepositoryCommitsLine } from "react-icons/ri";
import appraisalDoc from "../assets/documents/aefr-2014-4(12)-1878-1893.pdf";
import ileriPic from "../assets/images/IleriResidential.png";
import horizonPic from "../assets/images/HorizonMegaCity.png";

const clientSidebarData = {
  top: [
    {
      id: 1,
      text: "Dashboard",
      icon: <MdDashboard size={24} className="text-body" />,
      path: "/client-dashboard",
      label: "agentOverview",
    },
  ],
  bottom: [
    {
      id: 1,
      text: "Settings",
      icon: <MdSettings size={24} className="text-body" />,
      path: "/client-dashboard/settings",
      label: "agentSettings",
    },
    {
      id: 2,
      text: "Support",
      icon: <HiOutlineSupport size={24} className="text-body" />,
      label: "support",
    },
    {
      id: 3,
      text: "Logout",
      icon: <MdLogout size={24} className="text-body" />,
      label: "logout",
    },
  ],
};

const agentSidebarData = {
  top: [
    {
      id: 1,
      text: "Dashboard",
      icon: <MdDashboard size={24} className="text-body" />,
      path: "/agent-dashboard",
      label: "agentOverview",
    },
  ],
  bottom: [
    {
      id: 1,
      text: "Settings",
      icon: <MdSettings size={24} className="text-body" />,
      path: "/agent-dashboard/settings",
      label: "agentSettings",
    },
    {
      id: 2,
      text: "Support",
      icon: <HiOutlineSupport size={24} className="text-body" />,
      label: "support",
    },
    {
      id: 3,
      text: "Logout",
      icon: <MdLogout size={24} className="text-body" />,
      label: "logout",
    },
  ],
};

const adminSidebarData = {
  top: [
    {
      id: 1,
      text: "Dashboard",
      icon: <MdDashboard size={24} className="text-body" />,
      path: "/admin-dashboard",
      label: "adminOverview",
    },
    {
      id: 2,
      text: "Document Repository",
      icon: <RiGitRepositoryCommitsLine size={24} className="text-body" />,
      path: "/admin-dashboard/docs",
      label: "adminDocumentRepository",
    },
  ],
  bottom: [
    {
      id: 1,
      text: "Logout",
      icon: <MdLogout size={24} className="text-body" />,
      label: "logout",
    },
  ],
};

const getSidebarData = (role) => {
  if (role === "client") {
    return clientSidebarData;
  } else if (role === "agent") {
    return agentSidebarData;
  } else if (role === "admin") {
    return adminSidebarData;
  }
};

const getAgentOverviewData = (agent) => [
  {
    id: 1,
    text: "Downlines",
    title: agent?.downlines?.length,
    isMonetary: false,
    changeType: undefined,
    changePercent: undefined,
  },
  {
    id: 2,
    text: "Total Direct Sales",
    isMonetary: true,
    title: agent?.directSales?.value,
    changeType: agent?.directSales?.changeType,
    changePercent: agent?.directSales?.changePercent,
  },
  {
    id: 3,
    text: "Total Sales by Downlines",
    isMonetary: true,
    title: agent?.salesFromDownlines?.value,
    changeType: agent?.salesFromDownlines?.changeType,
    changePercent: agent?.salesFromDownlines?.changePercent,
  },
  {
    id: 4,
    text: "Total Commission",
    isMonetary: true,
    title: agent?.totalCommission?.value,
    changeType: agent?.totalCommission?.changeType,
    changePercent: agent?.totalCommission?.changePercent,
  },
];

const getAdminOverviewData = (admin) => [
  {
    id: 1,
    text: "Number of Properties for sale",
    title: admin?.downlines?.length,
    isMonetary: false,
  },
  {
    id: 2,
    text: "Total Revenue",
    isMonetary: true,
    title: admin?.directSales?.value,
  },
  {
    id: 3,
    text: "Total Clients",
    isMonetary: false,
    title: admin?.totalClients,
  },
  {
    id: 4,
    text: "Total Agents",
    isMonetary: false,
    title: admin?.totalAgents,
  },
];

const getClientOverviewData = (client) => [
  {
    id: 1,
    text: "Number of Properties",
    title: client?.directSales?.value,
    isMonetary: false,
    changeType: client?.directSales?.changeType,
    changePercent: client?.directSales?.changePercent,
  },
  {
    id: 2,
    text: "Total Value",
    isMonetary: true,
    title: client?.salesFromDownlines?.value,
    changeType: client?.salesFromDownlines?.changeType,
    changePercent: client?.salesFromDownlines?.changePercent,
  },
  {
    id: 2,
    text: "Current Value",
    isMonetary: true,
    title: client?.salesFromDownlines?.value,
    changeType: client?.salesFromDownlines?.changeType,
    changePercent: client?.salesFromDownlines?.changePercent,
  },
  {
    id: 3,
    text: "Return on Investment",
    isMonetary: false,
    title: client?.totalCommission?.value,
    changeType: client?.totalCommission?.changeType,
    changePercent: client?.totalCommission?.changePercent,
  },
];

const chartData = {
  weekly: {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    data: [150, 200, 180, 170, 200, 170, 130],
  },
  yearly: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    data: [
      700000, 1000000, 900000, 1800000, 1200000, 1500000, 1000000, 2000000,
      1500000, 2300000, 2000000, 2200000,
    ],
  },
  monthly: {
    labels: [
      "June 1",
      "June 2",
      "June 3",
      "June 4",
      "June 5",
      "June 6",
      "June 7",
      "June 8",
      "June 9",
      "June 10",
      "June 11",
      "June 12",
      "June 13",
      "June 14",
      "June 15",
      "June 16",
      "June 17",
      "June 18",
      "June 19",
      "June 20",
      "June 21",
      "June 22",
      "June 23",
      "June 24",
      "June 25",
      "June 26",
      "June 27",
      "June 28",
      "June 29",
      "June 30",
    ],
    data: [
      800, 1000, 950, 1100, 1000, 1150, 1400, 1200, 1150, 1000, 1300, 1250,
      1200, 1450, 1200, 1150, 1350, 1250, 1000, 1150, 1200, 1300, 1250, 1450,
      2000, 1700, 1550, 1700, 1800, 1550,
    ],
  },
};

const getClientChartData = (timeFrame) => {
  return {
    labels: chartData[timeFrame].labels,
    datasets: [
      {
        label: "Property Rating",
        data: chartData[timeFrame].data,
        borderColor: "red",
        fill: true,
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        lineTension: 0.5,
      },
    ],
  };
};

const buttonGroupData = [
  { name: "12 months", value: "yearly" },
  { name: "30 days", value: "monthly" },
  { name: "7 days", value: "weekly" },
];

const appraisalDocuments = [
  {
    name: "Appraisals and Valuation 1",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 2",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 3",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 4",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 5",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 6",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 7",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 8",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 9",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 10",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 11",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 12",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 13",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 14",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 15",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 16",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 17",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 18",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 19",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 20",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 21",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 22",
    date: new Date(),
    link: appraisalDoc,
  },
  {
    name: "Appraisals and Valuation 23",
    date: new Date(),
    link: appraisalDoc,
  },
];

const propertiesArray = [
  {
    name: "Horizon Mega City",
    location: "Moniya Ibadan",
    picture: horizonPic,
    area: "500 SQM",
    quantity: 0,
    price: 1850000,
  },
  {
    name: "Horizon Mega City",
    location: "Moniya Ibadan",
    picture: horizonPic,
    area: "300 SQM",
    quantity: 0,
    price: 1850000,
  },
  {
    name: "Horizon Mega City",
    location: "Moniya Ibadan",
    picture: horizonPic,
    area: "Acre",
    quantity: 0,
    price: 1850000,
  },
  {
    name: "Ileri Residentiala",
    location: "Ketu Epe",
    picture: ileriPic,
    area: "300 SQM",
    quantity: 0,
    price: 1850000,
  },
  {
    name: "Ileri Residentiala",
    location: "Ketu Epe",
    picture: ileriPic,
    area: "500 SQM",
    quantity: 0,
    price: 1850000,
  },
];

export {
  clientSidebarData,
  agentSidebarData,
  adminSidebarData,
  getSidebarData,
  getAgentOverviewData,
  getClientOverviewData,
  getClientChartData,
  buttonGroupData,
  appraisalDocuments,
  getAdminOverviewData,
  propertiesArray,
};
