import { Button, Col, Container, Ratio, Row } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const IleriYoutubeSection = ({ data }) => {
  return (
    <section>
      <Container className="pt-5 pb-0 pb-md-5 poppins-font" data-aos="fade-up">
        <Row className="position-relative mt-0 pt-5 pb-2 pb-md-5">
          <Col xs={12} className="mx-auto text-center">
            <h1 className="max-width-h1 mx-auto px-lg-5 fw-semibold mb-3 lh-base">
              Discover Our Amenities, Elevating Your Living Experience.
            </h1>
          </Col>
        </Row>
        <Row className="mt-0 pb-4 pb-md-0 position-relative" data-aos="fade-up">
          {data.youtubeLink ? (
            <Col xs={12} className="d-flex justify-content-center">
              <Ratio aspectRatio="16x9" className="w-100">
                <iframe
                  src="https://www.youtube.com/embed/XRSnbHMAh0k?si=O8vry-PkJ-Tx2jUM"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-100 h-100 rounded-4"
                ></iframe>
              </Ratio>
            </Col>
          ) : (
            <>
              <Button
                className="custom-carousel-icon position-absolute top-50 start-0 translate-middle-y z-3 ms-4 icon-prev rounded-circle border-0 bg-white"
                // disabled={currentIndex === 0}
              >
                <FaArrowLeft size={20} color="#1C498D" />
              </Button>

              <Button
                className="custom-carousel-icon position-absolute top-50 end-0 translate-middle-y z-3 me-4 icon-next rounded-circle border-0 bg-white"
                // disabled={currentIndex >= carouselData.length - itemsPerView}
              >
                <FaArrowRight size={20} color="#1C498D" />
              </Button>
              <Swiper
                slidesPerView={1}
                centeredSlides
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                pagination={{
                  type: "bullets",
                  bulletActiveClass: "orangeish-bg",
                }}
                // className="col-10"
                navigation={{
                  nextEl: ".icon-next",
                  prevEl: ".icon-prev",
                }}
                // autoplay
                loop
              >
                {data.aboutImages.map((item) => (
                  <SwiperSlide key={item.id}>
                    <img
                      src={item.imageUrl}
                      alt={item.altText}
                      className="img-fluid"
                      style={{ objectFit: "cover", borderRadius: "10px" }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default IleriYoutubeSection;
