import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";

const IleriNavbar = ({ data }) => (
  <Navbar bg="light" expand="lg" className="py-3 poppins-font">
    <Container>
      <Navbar.Brand
        as={Link}
        to="/"
        className="d-flex align-items-center gap-3 text-body"
      >
        <FaArrowLeftLong />
        <span>Back to ITech</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="d-none d-lg-inline mx-auto">
          <Navbar.Brand className="mx-auto">
            <img src={data.logo} alt="ILERI Logo" className="mx-auto" />
          </Navbar.Brand>
        </Nav>
        <Nav>
          {data.navLinks.map((link) => (
            <Nav.Link
              key={link.id}
              to={link.href}
              className="text-nowrap w-100 mt-2 mt-lg-0 fw-medium fs-6 custom-border ps-2 ps-lg-0 rounded-3 rounded-lg-0 text-body"
            >
              {link.text}
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default IleriNavbar;
