import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";

const IleriHeroCarousel = ({ carouselData }) => {
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const [itemsPerView, setItemsPerView] = useState(3);

  // const updateItemsPerView = () => {
  //   if (window.innerWidth >= 992) {
  //     setItemsPerView(3);
  //   } else if (window.innerWidth >= 768) {
  //     setItemsPerView(2);
  //   } else {
  //     setItemsPerView(1);
  //   }
  // };

  // useEffect(() => {
  //   updateItemsPerView();
  //   window.addEventListener("resize", updateItemsPerView);
  //   return () => {
  //     window.removeEventListener("resize", updateItemsPerView);
  //   };
  // }, []);

  // const handleNext = () => {
  //   if (currentIndex < carouselData.length - itemsPerView) {
  //     setCurrentIndex(currentIndex + 1);
  //   }
  // };

  // const handlePrev = () => {
  //   if (currentIndex > 0) {
  //     setCurrentIndex(currentIndex - 1);
  //   }
  // };

  // const visibleItems = carouselData.slice(
  //   currentIndex,
  //   currentIndex + itemsPerView
  // );

  return (
    <Container className="p-0">
      <section className="align-items-center w-11/12">
        {/* <Col xs={1} className="text-center" onClick={handlePrev}> */}
        {/* <Button
          className="custom-carousel-icon icon-prev orange-bg rounded-circle border-0"
          // disabled={currentIndex === 0}
        >
          <FaArrowLeft size={30} />
        </Button> */}
        {/* </Col> */}

        <Swiper
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 2000,
          }}
          loop
          breakpoints={{
            0: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            560: {
              slidesPerView: 2.5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
          }}
        >
          {carouselData.map((item) => (
            <SwiperSlide className="ileri-carousel-item" key={item.id}>
              <img
                src={item.imageUrl}
                alt={item.altText}
                className="d-block w-100"
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                  // maxHeight: 380,
                  height: 380,
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <Row className="ileri-carousel-wrapper"> */}
        {/* {visibleItems.map((item) => (
              <Col
                lg={12 / itemsPerView}
                md={12 / itemsPerView}
                xs={12}
                key={item.id}
                className={`ileri-carousel-item ileri-animate-slide`}
              >
                <img
                  src={item.imageUrl}
                  alt={item.altText}
                  className="d-block w-100 img-fluid"
                  style={{ objectFit: 'cover', borderRadius: '10px' }}
                />
              </Col>
            ))}
          </Row> */}

        {/* <Col xs={1} className="text-center" onClick={handleNext}> */}
        {/* <Button
          className="custom-carousel-icon icon-next orange-bg rounded-circle border-0"
          // disabled={currentIndex >= carouselData.length - itemsPerView}
        >
          <FaArrowRight size={30} />
        </Button> */}
        {/* </Col> */}
      </section>
    </Container>
  );
};

export default IleriHeroCarousel;
