import useScrollToTop from "../custom-hooks/useScrollToTop";
import CommonLandingPage from "../components/landing-page-components/ComonLandingPage";
import { horizonLandingPageData } from "../data/horizon-page-data";

const HorizonLandingPage = () => {
  useScrollToTop();

  return <CommonLandingPage data={horizonLandingPageData} />;
};

export default HorizonLandingPage;
