import aboutImgOne from "../assets/ileri-assets/about-img-one.png";
import aboutImgTwo from "../assets/ileri-assets/about-img-two.png";
import aboutImgThree from "../assets/ileri-assets/about-img-three.png";
import { downloadSvg } from "./protect-page-data";
import ileriLogo from "../assets/ileri-assets/ileri-residentials-logo.png";
import about1 from "../assets/ileri-assets/ileri-about-1.png";
import about2 from "../assets/ileri-assets/ileri-about-2.png";
import about3 from "../assets/ileri-assets/ileri-about-3.png";
import about12 from "../assets/ileri-assets/ileri-about-12.png";

export const ileriLandingPageData = {
  name: "ileri",
  logo: ileriLogo,
  navLinks: [
    {
      id: 1,
      href: "/overview",
      text: "Overview",
    },
    {
      id: 2,
      href: "/features",
      text: "Features",
    },
    {
      id: 3,
      href: "/contact-us",
      text: "Contact Us",
    },
  ],
  hero: {
    headings: [
      {
        id: 1,
        text: "Welcome to",
      },
      {
        id: 2,
        text: "Ileri Residentials",
        color: "#FF820C",
      },
    ],
    subHeading:
      "Relax, Recharge and Reconnect yourself with nature at Ileri Residentials",
    buttons: [
      {
        text: "Get Brochure",
        variant: "dark",
        icon: downloadSvg.icon,
      },
    ],
  },
  youtubeLink: "https://www.youtube.com/embed/XRSnbHMAh0k?si=O8vry-PkJ-Tx2jUM",
  carouselData: [
    {
      id: 1,
      imageUrl: about1,
      altText: "Slide 1",
    },
    {
      id: 2,
      imageUrl: about2,
      altText: "Slide 2",
    },
    {
      id: 3,
      imageUrl: about3,
      altText: "Slide 3",
    },
    {
      id: 4,
      imageUrl: about12,
      altText: "Slide 4",
    },
    {
      id: 5,
      imageUrl: about1,
      altText: "Slide 5",
    },
    {
      id: 6,
      imageUrl: about2,
      altText: "Slide 6",
    },
    {
      id: 7,
      imageUrl: about3,
      altText: "Slide 7",
    },
    {
      id: 10,
      imageUrl: about12,
      altText: "Slide 10",
    },
    {
      id: 11,
      imageUrl: about1,
      altText: "Slide 11",
    },
    {
      id: 12,
      imageUrl: about2,
      altText: "Slide 12",
    },
    {
      id: 13,
      imageUrl: about3,
      altText: "Slide 13",
    },
  ],
  description:
    "Ileri Residential is a visionary residential estate designed to redefine the standards of estate construction. Nestled in a serene and secure gated community, it offers an array of cutting-edge amenities for a modern lifestyle. With plot sizes ranging from 300SQM to 500SQM, you have the flexibility to choose the perfect space for your dream home.",
  plotsData: [
    {
      id: 1,
      size: 300,
      unit: "SQM",
    },
    {
      id: 2,
      size: 500,
      unit: "SQM",
    },
  ],
  amenities: [
    {
      id: 1,
      itemText: "Perimeter fencing",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 120 120"
          fill="none"
        >
          <path
            d="M30 40H50M30 90H50M70 40H90M70 90H90M20 15L10 25V100C10 103 12 105 15 105H25C28 105 30 103 30 100V25L20 15ZM60 15L50 25V100C50 103 52 105 55 105H65C68 105 70 103 70 100V25L60 15ZM100 15L90 25V100C90 103 92 105 95 105H105C108 105 110 103 110 100V25L100 15Z"
            stroke="#F06402"
            strokeWidth="7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 2,
      itemText: "Estate Security",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 120 120"
          fill="none"
        >
          <path
            d="M105 55C105 82.75 85.8 108.7 60 115C34.2 108.7 15 82.75 15 55V25L60 5L105 25V55ZM60 105C78.75 100 95 77.7 95 56.1V31.5L60 15.9L25 31.5V56.1C25 77.7 41.25 100 60 105Z"
            fill="#F06402"
          />
        </svg>
      ),
    },
    {
      id: 3,
      itemText: "Stable Electricity",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 120 120"
          fill="none"
        >
          <path
            d="M40.9 27.25L32 22.75L38.3 10H80.65L87 22.75L78.1 27.2L74.5 20H44.5L40.9 27.25ZM92.5 40H70L66 25H53L49.05 40H26.4L20 52.75L28.95 57.2L32.5 50H86.4L90 57.25L98.95 52.8L92.5 40ZM88.35 110H78L76.8 105.5L59.5 79.5L42.15 105.5L41 110H30.65L45 55H55.45L53.65 61.75L59.5 70.5L65.3 61.75L63.55 55H73.9L88.35 110ZM56.5 75L52 68.25L46.1 90.65L56.5 75ZM72.9 90.6L67 68.2L62.5 75L72.9 90.6Z"
            fill="#F06402"
          />
        </svg>
      ),
    },
    {
      id: 4,
      itemText: "Drainage System",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 120 120"
          fill="none"
        >
          <path
            d="M10 35L32.05 12.95C32.9803 12.0142 34.0866 11.2717 35.3052 10.7654C36.5237 10.2591 37.8304 9.99896 39.15 10H80.85C82.1696 9.99896 83.4763 10.2591 84.6948 10.7654C85.9134 11.2717 87.0197 12.0142 87.95 12.95L110 35M20 60V100C20 102.652 21.0536 105.196 22.9289 107.071C24.8043 108.946 27.3478 110 30 110H90C92.6522 110 95.1957 108.946 97.0711 107.071C98.9464 105.196 100 102.652 100 100V60"
            stroke="#FF820C"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M75 110V90C75 87.3478 73.9464 84.8043 72.0711 82.9289C70.1957 81.0536 67.6522 80 65 80H55C52.3478 80 49.8043 81.0536 47.9289 82.9289C46.0536 84.8043 45 87.3478 45 90V110M10 35H110V50C110 52.6522 108.946 55.1957 107.071 57.0711C105.196 58.9464 102.652 60 100 60C97.0787 59.8392 94.2886 58.7337 92.05 56.85C91.4534 56.4189 90.7361 56.1868 90 56.1868C89.2639 56.1868 88.5466 56.4189 87.95 56.85C85.7114 58.7337 82.9213 59.8392 80 60C77.0787 59.8392 74.2886 58.7337 72.05 56.85C71.4534 56.4189 70.7361 56.1868 70 56.1868C69.2639 56.1868 68.5466 56.4189 67.95 56.85C65.7114 58.7337 62.9213 59.8392 60 60C57.0787 59.8392 54.2886 58.7337 52.05 56.85C51.4534 56.4189 50.7361 56.1868 50 56.1868C49.2639 56.1868 48.5466 56.4189 47.95 56.85C45.7114 58.7337 42.9213 59.8392 40 60C37.0787 59.8392 34.2886 58.7337 32.05 56.85C31.4534 56.4189 30.7361 56.1868 30 56.1868C29.2639 56.1868 28.5466 56.4189 27.95 56.85C25.7114 58.7337 22.9213 59.8392 20 60C17.3478 60 14.8043 58.9464 12.9289 57.0711C11.0536 55.1957 10 52.6522 10 50V35Z"
            stroke="#FF820C"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 5,
      itemText: "Estate Security",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 120 120"
          fill="none"
        >
          <path
            d="M10 35L32.05 12.95C32.9803 12.0142 34.0866 11.2717 35.3052 10.7654C36.5237 10.2591 37.8304 9.99896 39.15 10H80.85C82.1696 9.99896 83.4763 10.2591 84.6948 10.7654C85.9134 11.2717 87.0197 12.0142 87.95 12.95L110 35M20 60V100C20 102.652 21.0536 105.196 22.9289 107.071C24.8043 108.946 27.3478 110 30 110H90C92.6522 110 95.1957 108.946 97.0711 107.071C98.9464 105.196 100 102.652 100 100V60"
            stroke="#F06402"
            strokeWidth="7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M75 110V90C75 87.3478 73.9464 84.8043 72.0711 82.9289C70.1957 81.0536 67.6522 80 65 80H55C52.3478 80 49.8043 81.0536 47.9289 82.9289C46.0536 84.8043 45 87.3478 45 90V110M10 35H110V50C110 52.6522 108.946 55.1957 107.071 57.0711C105.196 58.9464 102.652 60 100 60C97.0787 59.8392 94.2886 58.7337 92.05 56.85C91.4534 56.4189 90.7361 56.1868 90 56.1868C89.2639 56.1868 88.5466 56.4189 87.95 56.85C85.7114 58.7337 82.9213 59.8392 80 60C77.0787 59.8392 74.2886 58.7337 72.05 56.85C71.4534 56.4189 70.7361 56.1868 70 56.1868C69.2639 56.1868 68.5466 56.4189 67.95 56.85C65.7114 58.7337 62.9213 59.8392 60 60C57.0787 59.8392 54.2886 58.7337 52.05 56.85C51.4534 56.4189 50.7361 56.1868 50 56.1868C49.2639 56.1868 48.5466 56.4189 47.95 56.85C45.7114 58.7337 42.9213 59.8392 40 60C37.0787 59.8392 34.2886 58.7337 32.05 56.85C31.4534 56.4189 30.7361 56.1868 30 56.1868C29.2639 56.1868 28.5466 56.4189 27.95 56.85C25.7114 58.7337 22.9213 59.8392 20 60C17.3478 60 14.8043 58.9464 12.9289 57.0711C11.0536 55.1957 10 52.6522 10 50V35Z"
            stroke="#F06402"
            strokeWidth="7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 6,
      itemText: "Gym house",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 120 120"
          fill="none"
        >
          <path
            d="M37 35H23C22.2044 35 21.4413 35.3161 20.8787 35.8787C20.3161 36.4413 20 37.2044 20 38V82C20 82.394 20.0776 82.7841 20.2284 83.1481C20.3791 83.512 20.6001 83.8427 20.8787 84.1213C21.1573 84.3999 21.488 84.6209 21.8519 84.7716C22.2159 84.9224 22.606 85 23 85H37C37.394 85 37.7841 84.9224 38.1481 84.7716C38.512 84.6209 38.8427 84.3999 39.1213 84.1213C39.3999 83.8427 39.6209 83.512 39.7716 83.1481C39.9224 82.7841 40 82.394 40 82V38C40 37.2044 39.6839 36.4413 39.1213 35.8787C38.5587 35.3161 37.7956 35 37 35ZM97 35H83C82.2044 35 81.4413 35.3161 80.8787 35.8787C80.3161 36.4413 80 37.2044 80 38V82C80 82.394 80.0776 82.7841 80.2284 83.1481C80.3791 83.512 80.6001 83.8427 80.8787 84.1213C81.1573 84.3999 81.488 84.6209 81.8519 84.7716C82.2159 84.9224 82.606 85 83 85H97C97.394 85 97.7841 84.9224 98.1481 84.7716C98.512 84.6209 98.8427 84.3999 99.1213 84.1213C99.3999 83.8427 99.6209 83.512 99.7716 83.1481C99.9224 82.7841 100 82.394 100 82V38C100 37.2044 99.6839 36.4413 99.1213 35.8787C98.5587 35.3161 97.7956 35 97 35Z"
            stroke="#FF820C"
            strokeWidth="7.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40 60H80M5 72V48C5 47.2044 5.31607 46.4413 5.87868 45.8787C6.44129 45.3161 7.20435 45 8 45H17C17.7956 45 18.5587 45.3161 19.1213 45.8787C19.6839 46.4413 20 47.2044 20 48V72C20 72.7956 19.6839 73.5587 19.1213 74.1213C18.5587 74.6839 17.7956 75 17 75H8C7.20435 75 6.44129 74.6839 5.87868 74.1213C5.31607 73.5587 5 72.7956 5 72ZM115 72V48C115 47.2044 114.684 46.4413 114.121 45.8787C113.559 45.3161 112.796 45 112 45H103C102.204 45 101.441 45.3161 100.879 45.8787C100.316 46.4413 100 47.2044 100 48V72C100 72.7956 100.316 73.5587 100.879 74.1213C101.441 74.6839 102.204 75 103 75H112C112.796 75 113.559 74.6839 114.121 74.1213C114.684 73.5587 115 72.7956 115 72Z"
            stroke="#FF820C"
            strokeWidth="7.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ],
};

const ileriHeroHeadings = [
  {
    id: 1,
    text: "Welcome to",
  },
  {
    id: 2,
    text: "Ileri Residentials",
  },
];

const ileriButtonsData = [
  {
    text: "Get Brochure",
    variant: "dark",
    icon: downloadSvg.icon,
  },
];

const ileriFormData = [
  {
    label: "First Name",
    type: "text",
    name: "firstName",
    placeholder: "Enter First Name",
  },
  {
    label: "Last Name",
    type: "text",
    name: "lastName",
    placeholder: "Enter Last Name",
  },
  {
    label: "Phone Number",
    type: "tel",
    name: "phoneNumber",
    placeholder: "Enter Phone Number",
  },
  {
    label: "Email",
    type: "email",
    name: "email",
    placeholder: "Enter Email",
  },
  {
    label: "Schedule an inspection date",
    type: "date",
    name: "inspectionDate",
    placeholder: "DD/MM/YYYY",
  },
];

const calendarSvg = {
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3999 2.3999C7.89696 2.3999 8.2999 2.80285 8.2999 3.2999V4.7999H16.6999V3.2999C16.6999 2.80285 17.1028 2.3999 17.5999 2.3999C18.097 2.3999 18.4999 2.80285 18.4999 3.2999V4.7999H18.7999C20.6224 4.7999 22.0999 6.27736 22.0999 8.0999V18.2999C22.0999 20.1224 20.6224 21.5999 18.7999 21.5999H6.1999C4.37736 21.5999 2.8999 20.1224 2.8999 18.2999V8.0999C2.8999 6.27736 4.37736 4.7999 6.1999 4.7999H6.4999V3.2999C6.4999 2.80285 6.90285 2.3999 7.3999 2.3999ZM6.1999 8.9999C5.37148 8.9999 4.6999 9.67148 4.6999 10.4999V18.2999C4.6999 19.1283 5.37148 19.7999 6.1999 19.7999H18.7999C19.6283 19.7999 20.2999 19.1283 20.2999 18.2999V10.4999C20.2999 9.67148 19.6283 8.9999 18.7999 8.9999H6.1999Z"
        fill="#525252"
      />
    </svg>
  ),
};

const questionMarkSvg = {
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#461704"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

const ileriFaqData = [
  {
    id: 1,
    question: "Return on Investment",
    answer: "You have a guaranteed 100% returns of investment within 12 months",
  },
  {
    id: 2,
    question: "Where is Ileri Residential Estate Located?",
    answer:
      "Ileri Residential Estate is Located along Ketu-Omu road, Ketu-Epe, with a close proximity to the proposed International market and Epe resort and Spa.",
  },
  {
    id: 3,
    question: "What do I get after completion of payments for the land?",
    answer:
      "A duly executed receipt of purchase, Contract of Sale, Invoice, Copy of Survey plan, Letter of allocation ( After completion of payment for the land)",
  },
  {
    id: 4,
    question:
      "Can I pay a deposit and pay the balance anytime within the duration of the tenure chosen?",
    answer:
      "After the payment of initial deposit, you are expected to pay the balance within a duration of 9 months as non-payment as at when due will be treated as a breach of contract which can either result to termination or revocation of the contract.",
  },
  {
    id: 5,
    question:
      "Is there any restriction as to the type of building I can erect the land?",
    answer:
      "Yes. You are expected to build residential houses within the area so designated for such and commercial houses in areas designed as commercial. Shops in residential houses are however not allowed and building of tenement house type (otherwise known as face me and face you) are not permitted in the estate.",
  },
];

const imageItems = [
  {
    src: aboutImgOne,
  },
  [
    {
      src: aboutImgTwo,
    },
    {
      src: aboutImgThree,
    },
  ],
];

const ileriFooterLinksOne = [
  {
    path: "/overview",
    label: "Overview",
  },
  {
    path: "/features",
    label: "Features",
  },
  {
    path: "/pricing",
    label: "Pricing",
  },
  {
    path: "/careers",
    label: "Careers",
  },
  {
    path: "/help",
    label: "Help",
  },
  {
    path: "/privacy",
    label: "Privacy",
  },
];

const ileriFooterLinksTwo = [
  {
    path: "/terms",
    label: "Terms",
  },
  {
    path: "/privacy",
    label: "Privacy",
  },
  {
    path: "/cookies",
    label: "Cookies",
  },
];

export {
  ileriHeroHeadings,
  ileriButtonsData,
  ileriFormData,
  calendarSvg,
  questionMarkSvg,
  ileriFaqData,
  imageItems,
  ileriFooterLinksOne,
  ileriFooterLinksTwo,
};
