import { Link } from 'react-router-dom';
import iTechLogo from '../../assets/images/iTechLogo.svg';
import { firstFooterLinks, secondFooterLinks } from '../../data/landing-page-data';

const LandingPageFooter = () => {
  return (
    <footer className="footer" data-aos="fade-up">
      <section className="container">
        <img src={iTechLogo} alt="iTech Logo" className="footer-logo mb-5 mb-md-0" />
        <div className="first-footer-section pb-4 pb-md-0" data-aos="fade-up">
          {firstFooterLinks.map((link) => (
            <Link key={link.to} to={link.to} className="footer-link me-2">
              {link.label}
            </Link>
          ))}
        </div>
        <div className="second-footer-section">
          <h3>&copy; {new Date().getFullYear()}, iTech Homes and Properties. All rights reserved.</h3>
          <div className="second-footer-section-links">
            {secondFooterLinks.map((link) => (
              <Link key={link.to} to={link.to} className="footer-link me-2">
                {link.label}
              </Link>
            ))}
          </div>
        </div>
      </section>
    </footer>
  );
};

export default LandingPageFooter;
