import { Container, Row, Col } from "react-bootstrap";

const IleriAmenitiesSection = ({ data }) => {
  return (
    <Container className="my-5 pb-5 poppins-font" data-aos="fade-up">
      <Row>
        <Col lg={6} className="mb-4">
          <h3 className="fw-bold mb-2 py-2 px-3 px-md-0">Description</h3>
          <p className="lead fs-6 small-text line-medium px-3 px-md-0">
            {data.description}
          </p>
          <h3 className="fw-bold mb-2 pt-4 pb-2 px-3 px-md-0">Plot Size</h3>
          <div className="d-flex px-3 px-md-0">
            {data.plotsData.map(({ id, size, unit }) => (
              <div
                key={id}
                className={`p-3 ${
                  id !== data.plotsData.length ? "border-dark border-end" : ""
                }`}
              >
                <h2 className="text-secondary text-center">{size}</h2>
                <p className="text-center text-secondary mb-0">{unit}</p>
              </div>
            ))}
          </div>
        </Col>

        <Col lg={6}>
          <h3 className="fw-bold mb-3 mb-md-2 py-2 px-3 px-md-0 mt-5 mt-md-0">
            Amenities
          </h3>
          <Row className="g-3 px-3 px-md-0">
            {data.amenities.map((amenity) => (
              <Col key={amenity.id} xs={6} md={4}>
                <div className="py-5 px-3 rounded bg-body-secondary d-flex flex-column align-items-center gap-3 h-100">
                  <span
                    style={{ width: 64, height: 64 }}
                    className="rounded-circle bg-white d-flex justify-content-center align-items-center"
                  >
                    {amenity.svgIcon}
                  </span>
                  <p className="pb-2 mb-0 text-nowrap text-body fw-medium text-center">
                    {amenity.itemText}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default IleriAmenitiesSection;
