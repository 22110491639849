import useScrollToTop from "../custom-hooks/useScrollToTop";
import { ileriLandingPageData } from "../data/ileri-page-data";
import CommonLandingPage from "../components/landing-page-components/ComonLandingPage";

const IleriLandingPage = () => {
  useScrollToTop();

  return <CommonLandingPage data={ileriLandingPageData} />;
};

export default IleriLandingPage;
