import UserBox from "../../components/dashboard-components/UserBox";

const AllClients = ({ data }) => {
  return (
    <>
      {
        data.length ? data?.map((userInfo) => (
          // <UserBox id={_id} name={`${lastName} ${firstName}`} picture={profilePictureUrl} role={role} key={_id} />
          <UserBox userInfo={userInfo} key={userInfo?._id} />
        
        ))
      : <h4 className="text-danger-emphasis">No Clients Found</h4>
      }
    </>
  );
};

export default AllClients;
