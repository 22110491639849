import { Card, Col, Row } from "react-bootstrap";
import DashboardRootLayout from "../../components/dashboard-components/DashboardRootLayout";
import UsersList from "./UsersList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAdminOverviewData } from "../../data/dashboard-data";
import { TbCurrencyNaira } from "react-icons/tb";
import { getAllUsers } from "../../redux/reducers/adminReducer";
import FullLoader from "../../components/loader-components/Loaders";
import utils from "../../utils";

// NOTE: This is just a markup, No styling was done..just continue from here
const AdminDashboard = () => {
  const { allUsers } = useSelector((state) => state.admin);
  const { user, fetchLoading } = useSelector((state) => state.user.userData || {});

  const adminData = {
    ...user,
    totalClients: utils.filterUsersByRole("clients", allUsers).length,
    totalAgents: utils.filterUsersByRole("agents", allUsers).length
  };
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) dispatch(getAllUsers());
  }, [user, dispatch]);

  return (
    <DashboardRootLayout>
      {
        fetchLoading ? (
          <FullLoader loaderText="Fetching Admin Profile" isColored />
        ) : (
          <section className="p-3">
            <aside>
              <header className="text-start">
                <h1 className="text-itechRed fs-1 fw-semibold">Welcome, ADMIN</h1>
                <p>Follow up with activities of your customers and agents!</p>
              </header>
              <Row className="g-4">
                {getAdminOverviewData(adminData)?.map(({ id, text, title, isMonetary, changeType, changePercent }) => (
                  <Col key={id} xs={12} md={3} className="d-flex">
                    <Card className="w-100 flex-fill">
                      <Card.Body className="d-flex flex-column gap-2 justify-content-between">
                        <Card.Title className="my-0 moresmaller-text">{text}</Card.Title>
                        <Card.Text className="mb-0 small-text text-end">
                          {isMonetary && <TbCurrencyNaira size={21} />}
                          {title}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </aside>
            <UsersList />
          </section>
        )}
    </DashboardRootLayout>
  );
};

// Add new agent sales
// Add new agent commissions


export default AdminDashboard;
