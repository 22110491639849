import { useEffect, useState } from "react";
import { Container, Row, Col, Nav, Button, Card } from "react-bootstrap";
import AllUsersList from "./AllUsersList";
import AllClients from "./AllClients";
import AllAgents from "./AllAgents";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import utils from "../../utils";

const UsersList = () => {
  const [activeTab, setActiveTab] = useState("All Users");
  const [searchTerm, setSearchTerm] = useState("");
  const allUsers = useSelector((state) => state.admin.allUsers) || [];
  const [filteredUsers, setFilteredUsers] = useState(allUsers);
  
  const [numberOfProfilesAtOnce, setnumberOfProfilesAtOnce] = useState(8);
  const [page, setPage] = useState(1);

  const handleNextPage = () => {
    if (page <= Math.floor((activeTab === "All Users" ? allUsers : filteredUsers).length / numberOfProfilesAtOnce)) {
      setPage((prev) => prev += 1);
    } else {
      setPage(page);
    }
  }
  
  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prev) => prev -= 1);
    } else {
      setPage(page);
    }
  }

  useEffect(() => {
    if (!allUsers.length) return;
    // Filtering without a search term
    if (!searchTerm) {
      activeTab === "All Users"
        ? setFilteredUsers(allUsers)
        : setFilteredUsers(utils.filterUsersByRole(activeTab, allUsers));
      return;
    }
    // Searching through the users using name
    const filtered = filteredUsers.filter(({firstName, lastName}) => (`${lastName} ${firstName}`.toLowerCase()).includes(searchTerm.toLowerCase()));
    setFilteredUsers(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, activeTab]);

  useEffect(() => {
    setPage(1);
    setSearchTerm("");
    setnumberOfProfilesAtOnce(8);
  }, [activeTab]);

  useEffect(() => {
    setFilteredUsers(allUsers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataToDisplay = filteredUsers.slice(numberOfProfilesAtOnce * (page - 1), (filteredUsers.length >= (page * numberOfProfilesAtOnce) ? page * numberOfProfilesAtOnce : filteredUsers.length));
  
  const tabs = [
    { label: "All Users", content: <AllUsersList data={dataToDisplay} /> },
    { label: "Clients", content: <AllClients data={dataToDisplay} /> },
    { label: "Agents", content: <AllAgents data={dataToDisplay} /> },
  ];

  const renderContent = () => {
    const activeTabData = tabs.find((tab) => tab.label === activeTab);
    return activeTabData?.content;
  };

  return (
    <section className="mt-5 mb-4">
      <Card>
        <Card.Body>
          <Card.Title>Users List</Card.Title>
          <Container>
            <Row className="justify-content-center mb-4 border-top border-bottom border-secondary-subtle pt-3">
              <Nav variant="underline" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)}>
                {tabs.map((tab) => (
                  <Nav.Item key={tab.label}>
                    <Nav.Link eventKey={tab.label} className={`bg-transparent px-2 text-capitalize ${activeTab === tab.label ? "active-tab" : "text-secondary"}`} style={{ color: activeTab === tab.label ? "#FF000E" : "" }}>
                      {`${tab.label} (${allUsers?.length && utils.filterUsersByRole(tab.label, allUsers)?.length})`}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={5} className="position-relative">
                <input type="search" placeholder="Search name" className="ps-5 py-2 w-100 rounded-2 searchBox" value={searchTerm} onChange={(e) => setSearchTerm(e.currentTarget.value)} />
                <CiSearch className="position-absolute middle-y" size={24} />
              </Col>
            </Row>
            <Row className="col-gap-md-0 w-100 row-gap-3 align-items-stretch">
              {renderContent()}
            </Row>
            <div className="d-flex justify-content-end mt-2">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setPage(1);
                  setnumberOfProfilesAtOnce(numberOfProfilesAtOnce === 8 ? filteredUsers.length : 8)
                }}
              >See {numberOfProfilesAtOnce === 8 ? "all" : "less"}</Button>
            </div>
            <div className="d-flex justify-content-between align-items-center mx-2 pt-4">
              <Button className="bg-brand fw-medium text-white border-0 py-1 align-baseline" onClick={handlePrevPage}>
                <BiArrowToLeft /> Previous
              </Button>
              <span>Page {page} of {Math.ceil(filteredUsers.length / numberOfProfilesAtOnce)}</span>
              <Button className="bg-brand fw-medium text-white border-0 py-1 align-baseline" onClick={handleNextPage}>
                Next <BiArrowToRight />
              </Button>
            </div>
          </Container>
        </Card.Body>
      </Card>
    </section>
    
  );
};

export default UsersList;
