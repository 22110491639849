import { useEffect } from "react";
import { Container, Row, Col, Button, Figure, Image } from "react-bootstrap";
import "aos/dist/aos.css";
import AOS from "aos";
import IleriHeroCarousel from "./IleriHeroCarousel";
import useResize from "../../custom-hooks/useResize";

const IleriHeroSection = ({ data }) => {
  const { width } = useResize();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section
      className="bg-light pt-2 pb-5 overflow-x-hidden poppins-font"
      data-aos="fade-right"
    >
      <Container>
        <Row className="position-relative mt-4 py-5">
          <Col xs={12} lg={8} className="mx-auto text-center">
            {width < 992 && (
              <Figure className="d-flex align-items-center justify-content-center mb-4">
                <Image src={data.logo} />
              </Figure>
            )}
            {data.hero.headings.map(({ id, text, color }) => (
              <h1
                key={id}
                className={`display-4 fw-semibold ${
                  id === 2 ? "mb-3" : "mb-2"
                }`}
                style={{ color }}
              >
                {text}
              </h1>
            ))}
            <div className="d-flex justify-content-center">
              <p className="w-75 lead text-muted fs-5 small-text line-larger mb-5 px-4">
                {data.hero.subHeading}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-center gap-3">
              {data.hero.buttons.map((button, index) => (
                <Button
                  key={index}
                  variant={button.variant}
                  className="fw-medium btn-padding-lg rounded-pill border-none btn-outline-none d-flex align-items-center justify-content-center gap-2"
                >
                  <span>{button.text}</span>
                  {button.icon && button.icon}
                </Button>
              ))}
            </div>
          </Col>
        </Row>
      </Container>

      <IleriHeroCarousel carouselData={data.carouselData} />
    </section>
  );
};

export default IleriHeroSection;
